import { FunctionComponent, useMemo, type CSSProperties } from "react";
import TypeTitleDescriptionColu from "./type-title-description-colu";
import Property1Shopify from "./property1-shopify";

type LoyaltyProgramContainerType = {
  sectionTitle?: string;
  description?: string;
  clientLogoMark?: string;
  clientLogoMark1?: string;
  clientLogoMark2?: string;
  clientLogoMark3?: string;
  clientLogoMark4?: string;
  clientLogoMark5?: string;
  clientLogoMark6?: string;

  /** Style props */
  propBorder?: CSSProperties["border"];
  propJustifyContent?: CSSProperties["justifyContent"];
};

const LoyaltyProgramContainer: FunctionComponent<
  LoyaltyProgramContainerType
> = ({
  sectionTitle,
  description,
  clientLogoMark,
  clientLogoMark1,
  clientLogoMark2,
  clientLogoMark3,
  clientLogoMark4,
  clientLogoMark5,
  clientLogoMark6,
  propBorder,
  propJustifyContent,
}) => {
  const typeTitleDescriptionColuStyle: CSSProperties = useMemo(() => {
    return {
      border: propBorder,
    };
  }, [propBorder]);

  const logosStyle: CSSProperties = useMemo(() => {
    return {
      justifyContent: propJustifyContent,
    };
  }, [propJustifyContent]);

  return (
    <div className="resp-loyality-section-flex-wrap flex flex-row items-start justify-start gap-[32px]">
      <TypeTitleDescriptionColu
        sectionTitle="200+ Loyalty Programs"
        description="Track loyalty programs across hotels, airlines, cards all around the globe"
        typeTitleDescriptionColuBorder="1px solid #fff"
        typeTitleDescriptionColuBoxSizing="border-box"
        typeTitleDescriptionColuFlexShrink="0"
        typeTitleDescriptionColuPadding="40px 0px"
        typeTitleDescriptionColuWidth="488px"
        sectionTitleTextAlign="left"
        descriptionTextAlign="left"
      />
      <div className="resp-static-flex-box relative w-[696px] h-[270px] shrink-0">
        <img
          className="resp-absolute-bg-img resp-w-100 absolute top-[0px] left-[78px]"
          alt=""
          src="/vector.svg"
        />
        <div
          className="absolute top-[40px] left-[124px] flex flex-col items-center justify-center gap-[64px]"
          style={logosStyle}
        >
          <div className="resp-icons-flex-box flex flex-row items-center justify-start gap-[64px]">
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark7.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark8.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark9.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark10.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
          </div>
          <div className="resp-icons-flex-box flex flex-row items-center justify-start gap-[64px]">
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark11.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark12.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
            <Property1Shopify
              property1ShopifyProperty1="/-client-logo-mark13.svg"
              property1ShopifyIconWidth="104px"
              property1ShopifyIconHeight="104px"
              property1ShopifyIconPosition="relative"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyProgramContainer;
