import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Example01Desktop from "./pages/example01-desktop";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/terms-and-conditions";
import PageNotFound from "./pages/PageNotFound/page-not-found";
import CancellationRefundPolicy from "./pages/cancellation-refund-policy";
import ClubVistaraSweetSpots from "./pages/clubVistaraSweetSpots/club-vistara-sweet-spots";
import ClubVistaraSweetSpotsResult from "./pages/ClubVistaraSweetSpotsResult/clubVistaraSweetSpotsResult";
import ReactGA from "react-ga4";
import Manifest from "./constants/Manifest";
import {
  getTitleAndMetaDescription,
  setCanonicalLink,
  setOpenGraphAndTwitterMetaTags,
  setTitleAndMetaDescription,
} from "./constants/Seo-config";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(function initializeGoogleAnalytics() {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.initialize(Manifest.GOOGLE_ANALYTICS_TAG_ID);
    }
  }, []);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    const { title, metaDescription } = getTitleAndMetaDescription({ pathname });
    setTitleAndMetaDescription({ title, metaDescription });
    setOpenGraphAndTwitterMetaTags({ title, metaDescription });
    setCanonicalLink({ title, metaDescription });
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Example01Desktop />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route
        path="/cancellation-refund-policy"
        element={<CancellationRefundPolicy />}
      />
      <Route
        path="/club-vistara-sweet-spots-before-merger"
        element={<ClubVistaraSweetSpots />}
      />
      <Route
        path="/club-vistara-sweet-spots-before-merger/results"
        element={<ClubVistaraSweetSpotsResult />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
export default App;
