import { FunctionComponent } from "react";
import HomeContainer from "../components/home-container";
import RewardManagementContainer from "../components/reward-management-container";
import Header from "../components/header";
import RewardsSection1 from "../components/rewards-section1";
import LoyaltyProgramsSection1 from "../components/loyalty-programs-section1";
import BottomFooter from "../components/bottom-footer";

const Example01Desktop: FunctionComponent = () => {
  return (
    <div className="resp-main-container relative bg-white w-full overflow-y-auto flex flex-col items-center justify-start">
      <section className="self-stretch bg-white overflow-hidden shrink-0 flex flex-col items-center justify-start relative gap-[10px]">
        <HomeContainer />
        <RewardManagementContainer />
        <Header />
      </section>
      <RewardsSection1 />
      <LoyaltyProgramsSection1 />
      <BottomFooter />
    </div>
  );
};

export default Example01Desktop;
