import { FunctionComponent } from "react";
import Features12 from "./features12";

const RewardsSection1: FunctionComponent = () => {
  return (
    <section
      className="self-stretch flex flex-col items-center justify-start"
      id="features"
    >
      <Features12 />
    </section>
  );
};

export default RewardsSection1;
