import { FunctionComponent } from "react";
import TypeDefaultBreakpointDeskt from "./type-default-breakpoint-deskt";
import { useNavigate } from "react-router-dom";

const NavGroup: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="overflow-hidden flex flex-row items-center justify-start gap-[8px] mt-1.5 text-left text-sm text-neutral-900 font-body-lg-medium">
      <div className="flex-row items-center justify-start hidden px-2 py-1">
        <div className="relative leading-[20px] font-semibold">Terms</div>
      </div>
      <div
        onClick={openBlog}
        className="flex-row items-center justify-start px-1 py-1 cursor-pointer md:px-2 hover:underline"
      >
        <div className="relative leading-[20px] font-semibold">Blog</div>
      </div>
      <div
        onClick={openClubVistaraSweetSpotsPage}
        className="flex-row items-center justify-start px-1 py-1 cursor-pointer md:px-2 hover:underline text-[#7165E3] md:flex hidden"
      >
        <div className="relative leading-[20px] font-semibold ">
          Vistara Gems
        </div>
      </div>
    </div>
  );
  function openBlog() {
    window.open("https://blog.magnify.club", "_self");
  }
  function openClubVistaraSweetSpotsPage() {
    navigate("/club-vistara-sweet-spots-before-merger");
  }
};

export default NavGroup;
