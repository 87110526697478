import { FunctionComponent, useEffect, useRef, useState } from "react";
import NavGroup from "./nav-group";
import AppBadges from "./app-badges";
import { useNavigate } from "react-router-dom";

const Header: FunctionComponent = () => {
  const [showList, setShowList] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [dropdownRef, toggleButtonRef]);
  return (
    <header
      className="my-0 mx-[!important] absolute top-[0px] left-[0px] flex flex-row items-center justify-start py-5 px-28 gap-[642px] z-20 text-left text-xl text-neutral-900 font-raleway w-[100%]"
      id="navBar"
    >
      <div className="overflow-hidden flex flex-row items-center justify-start md:gap-[32px] gap-[20px]">
        <div onClick={openHome} className="cursor-pointer">
          <div className="relative w-32 h-8 overflow-hidden shrink-0">
            <div className="absolute top-[21.25%] left-[32.31%] leading-[20px] font-extrabold">
              Magnify
            </div>
            <img
              className="absolute h-[13.13%] w-[5.99%] top-[15%] right-[0.2%] bottom-[71.87%] left-[93.81%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/tm.svg"
            />
            <img
              className="absolute top-[0.8px] left-[7px] w-8 h-8 overflow-hidden"
              alt=""
              src="/applogo.svg"
            />
          </div>
        </div>
        <NavGroup />
      </div>
      {/* <AppBadges storeBadge="/store-badge5.svg" /> */}
      <div
        ref={toggleButtonRef}
        className="absolute right-[2rem] flex md:hidden"
      >
        <img
          src="/navbars.png"
          onClick={() => setShowList((prev) => !prev)}
          className="w-[1.3rem] h-[1rem]"
        />
      </div>
      {showList && (
        <div
          ref={dropdownRef}
          className="z-20 bg-white rounded-lg w-[10rem] absolute right-[2rem] top-[3.5rem] "
        >
          <div
            onClick={openHome}
            className="text-[1rem] font-medium px-3 py-2 text-[#1C1939] border-[#857fbb] font-dm border-2 border-solid rounded-t-lg cursor-pointer"
          >
            Home
          </div>
          <div
            onClick={openBlog}
            className="text-[1rem] font-medium px-3 py-2 text-[#1C1939] border-[#857fbb] font-dm border-2 border-t-0 border-solid  cursor-pointer"
          >
            Blog
          </div>
          <div
            onClick={openClubVistaraSweetSpotsPage}
            className="text-[1rem] font-medium px-3 py-2 text-[#1C1939] border-[#857fbb] font-dm border-2 border-solid border-t-0 rounded-b-lg cursor-pointer"
          >
            Vistara Gems
          </div>
        </div>
      )}
    </header>
  );

  function openHome() {
    window.open("/", "_self");
  }
  function openBlog() {
    window.open("https://blog.magnify.club", "_self");
  }
  function openClubVistaraSweetSpotsPage() {
    navigate("/club-vistara-sweet-spots-before-merger");
  }
};

export default Header;
