import { FunctionComponent, useMemo, type CSSProperties } from "react";

type StoreGooglePlayColorBrandType = {
  dimensionCode?: string;

  /** Style props */
  storeGooglePlayColorBrandPosition?: CSSProperties["position"];
  storeGooglePlayColorBrandFlexShrink?: CSSProperties["flexShrink"];
  storeLink: string;
};

const StoreGooglePlayColorBrand: FunctionComponent<
  StoreGooglePlayColorBrandType
> = ({
  dimensionCode,
  storeGooglePlayColorBrandPosition,
  storeGooglePlayColorBrandFlexShrink,
  storeLink,
}) => {
  const storeGooglePlayColorBrandStyle: CSSProperties = useMemo(() => {
    return {
      position: storeGooglePlayColorBrandPosition,
      flexShrink: storeGooglePlayColorBrandFlexShrink,
      cursor: "pointer",
    };
  }, [storeGooglePlayColorBrandPosition, storeGooglePlayColorBrandFlexShrink]);

  return (
    <img
      className="w-[135px] h-10 overflow-hidden"
      alt=""
      onClick={() => {
        window.open(storeLink, "_blank");
      }}
      src={dimensionCode}
      style={storeGooglePlayColorBrandStyle}
    />
  );
};

export default StoreGooglePlayColorBrand;
