import { FunctionComponent } from "react";
import AppStoreLinks from "./AppStoreLinks";

type BreakpointDesktopType = {
  iconImageUrl?: string;
  pageTitle?: string;
  linkText?: string;
  navItemText?: string;
  copyrightText?: string;
  showLogomarkIcon?: boolean;
  showLinksRow1?: boolean;
  showAbout?: boolean;
  showFeatures?: boolean;
  emailAddress?: string;
  companyAddress?: string;
};

const BreakpointDesktop: FunctionComponent<BreakpointDesktopType> = ({
  iconImageUrl,
  pageTitle,
  linkText,
  navItemText,
  copyrightText,
  showLogomarkIcon,
  showLinksRow1,
  showAbout,
  showFeatures,
  emailAddress,
  companyAddress,
}) => {
  function onPrivacyPolicy() {
    window.open("/privacy-policy", "_blank", "noreferrer");
  }

  function onTermsAndConditions() {
    window.open("/terms-and-conditions", "_blank", "noreferrer");
  }

  function onBlog() {
    window.open("https://blog.magnify.club", "_blank", "noreferrer");
  }

  function onCancellationAndRefundPolicy() {
    window.open("/cancellation-refund-policy", "_blank", "noreferrer");
  }

  return (
    <div className="resp-footer bg-black w-[1440px] flex flex-row items-start justify-between py-16 px-28 box-border text-left text-base text-white font-body-lg-medium">
      <div className="overflow-hidden flex flex-col items-start justify-start gap-[24px]">
        <div className="flex flex-row items-center justify-center">
          <AppStoreLinks col={false} />
        </div>
        {showLogomarkIcon && (
          <img className="relative w-8 h-8" alt="" src={iconImageUrl} />
        )}
        <div className="overflow-hidden flex flex-col items-start justify-start gap-[12px]">
          {showLinksRow1 && (
            <div className="overflow-hidden flex flex-row items-center justify-start gap-[24px]">
              <div className="relative leading-[24px]">Download Now</div>
              <div className="relative leading-[24px]">License</div>
            </div>
          )}
          <div className="overflow-hidden flex flex-row items-center justify-start gap-[24px]">
            {showAbout && <div className="relative leading-[24px]">About</div>}
            {showFeatures && (
              <div className="relative leading-[24px]">Features</div>
            )}
            {/* <div className="relative leading-[24px]">{pageTitle}</div>
            <div className="relative leading-[24px]">{linkText}</div> */}

            <div
              className="relative leading-[24px] cursor-pointer"
              onClick={onPrivacyPolicy}
            >
              Privacy Policy
            </div>
            <div
              className="relative leading-[24px] cursor-pointer"
              onClick={onTermsAndConditions}
            >
              Terms & Conditions
            </div>
            <div
              className="relative leading-[24px] cursor-pointer"
              onClick={onCancellationAndRefundPolicy}
            >
              Cancellation & Refund Policy
            </div>
            <div
              className="relative leading-[24px] cursor-pointer"
              onClick={onBlog}
            >
              Blog
            </div>
            <div className="relative leading-[24px]">{navItemText}</div>
          </div>
        </div>
        <div className="relative text-sm leading-[20px] text-neutral-300">
          {copyrightText}
        </div>
        <div className="relative text-sm leading-[20px] text-neutral-300">
          {emailAddress}
        </div>
        <div className="relative text-sm leading-[20px] text-neutral-300">
          {companyAddress}
        </div>
      </div>
    </div>
  );
};

export default BreakpointDesktop;
