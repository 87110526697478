import { FunctionComponent } from "react";
import LogoClouds06 from "./logo-clouds06";

const LoyaltyProgramsSection1: FunctionComponent = () => {
  return (
    <div className="resp-w-100 flex flex-col items-center justify-start">
      <LogoClouds06 />
    </div>
  );
};

export default LoyaltyProgramsSection1;
