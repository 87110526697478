import React, { useEffect, useState } from "react";
import StoreGooglePlayColorBrand from "./store-google-play-color-brand";
import Manifest from "../constants/Manifest";

const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000;
const hasSevenDaysPassed = (timestamp: number): boolean => {
  const now = new Date().getTime();
  return now - timestamp > SEVEN_DAYS_IN_MS;
};

export default function AppStoreLinks({ col = true, showExpDialog = false }) {
  const [showBottomSheet, setShowBottomSheet] = useState(false);

  useEffect(() => {
    if (isAndroid()) {
      const lastDismissed = localStorage.getItem("lastDismissed");
      if (!lastDismissed || hasSevenDaysPassed(Number(lastDismissed))) {
        setShowBottomSheet(true);
      }
    }
  }, []);

  const handleDownload = () => {
    window.location.href = Manifest.GOOGLE_PLAY_STORE_LINK;
  };

  const handleClose = () => {
    localStorage.setItem("lastDismissed", new Date().getTime().toString());
    setShowBottomSheet(false);
  };

  const className = `flex ${
    col ? "flex-col" : "flex-row"
  } items-start justify-start gap-[16px] text-left text-lg text-neutral-300 font-body-lg-medium`;

  return (
    <div>
      <div className={className}>
        <StoreGooglePlayColorBrand
          dimensionCode="/store-badge6.svg"
          storeGooglePlayColorBrandPosition="relative"
          storeGooglePlayColorBrandFlexShrink="0"
          storeLink={Manifest.IOS_APP_STORE_LINK}
        />
        <StoreGooglePlayColorBrand
          dimensionCode="/store-badge7.svg"
          storeGooglePlayColorBrandPosition="relative"
          storeGooglePlayColorBrandFlexShrink="0"
          storeLink={Manifest.GOOGLE_PLAY_STORE_LINK}
        />

        {showBottomSheet && showExpDialog && (
          <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg z-50">
            <p className="text-lg text-black">
              Download our app for a better experience.
            </p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleClose}
                className="text-gray-600 mr-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDownload}
                style={{ backgroundColor: "#7165E3" }}
                className="text-white py-2 px-4 rounded"
              >
                Download
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
