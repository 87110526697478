import { FunctionComponent, useMemo, type CSSProperties } from "react";

type SizexlColorTertiaryIconType = {
  essentialIcon?: string;
  label?: string;
  showButtonBase?: boolean;

  /** Style props */
  essentialIconOverflow?: CSSProperties["overflow"];
  essentialIconFlexShrink?: CSSProperties["flexShrink"];
  labelColor?: CSSProperties["color"];
};

const SizexlColorTertiaryIcon: FunctionComponent<
  SizexlColorTertiaryIconType
> = ({
  essentialIcon,
  label,
  showButtonBase,
  essentialIconOverflow,
  essentialIconFlexShrink,
  labelColor,
}) => {
  const essentialIconStyle: CSSProperties = useMemo(() => {
    return {
      overflow: essentialIconOverflow,
      flexShrink: essentialIconFlexShrink,
    };
  }, [essentialIconOverflow, essentialIconFlexShrink]);

  const labelStyle: CSSProperties = useMemo(() => {
    return {
      color: labelColor,
    };
  }, [labelColor]);

  return (
    <div className="rounded-lg flex flex-row items-center justify-center text-center text-base text-primary-600 font-body-lg-medium">
      {showButtonBase && (
        <div className="rounded-lg flex flex-row items-center justify-center py-4 px-6 gap-[8px]">
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0"
            alt=""
            src={essentialIcon}
            style={essentialIconStyle}
          />
          <div
            className="relative leading-[24px] font-semibold"
            style={labelStyle}
          >
            {label}
          </div>
        </div>
      )}
    </div>
  );
};

export default SizexlColorTertiaryIcon;
