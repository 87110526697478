import { FunctionComponent, useMemo, type CSSProperties } from "react";
import TypeIconWithContentCenter from "./type-icon-with-content-center";

type FeaturesContainerType = {
  headline?: string;
  description?: string;
  headline1?: string;
  description1?: string;
  description2?: string;
  headline2?: string;
  description3?: string;
  headline3?: string;
  description4?: string;
  baseFeatureIcon?: string;
  headline4?: string;
  description5?: string;

  /** Style props */
  propWidth?: CSSProperties["width"];
  propFlex?: CSSProperties["flex"];
  propWidth1?: CSSProperties["width"];
  propFlex1?: CSSProperties["flex"];
  propWidth2?: CSSProperties["width"];
  propFlex2?: CSSProperties["flex"];
  propWidth3?: CSSProperties["width"];
};

const FeaturesContainer: FunctionComponent<FeaturesContainerType> = ({
  headline,
  description,
  headline1,
  description1,
  description2,
  headline2,
  description3,
  headline3,
  description4,
  baseFeatureIcon,
  headline4,
  description5,
  propWidth,
  propFlex,
  propWidth1,
  propFlex1,
  propWidth2,
  propFlex2,
  propWidth3,
}) => {
  const row01Style: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const typeIconWithContentCenterStyle: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
      width: propWidth1,
    };
  }, [propFlex, propWidth1]);

  const typeIconWithContentCenterStyle1: CSSProperties = useMemo(() => {
    return {
      flex: propFlex1,
      width: propWidth2,
    };
  }, [propFlex1, propWidth2]);

  const typeIconWithContentCenterStyle2: CSSProperties = useMemo(() => {
    return {
      flex: propFlex2,
      width: propWidth3,
    };
  }, [propFlex2, propWidth3]);

  return (
    <div className="overflow-hidden flex flex-col items-start justify-start gap-[64px]">
      <div
        className="resp-flex-list-items-wrapper w-[1212px] overflow-hidden flex flex-row items-center justify-start gap-[30px]"
        style={row01Style}
      >
        <TypeIconWithContentCenter
          baseFeatureIcon="/base-feature-icon.svg"
          headline="Personalized"
          description="We will suggest you the best possible way to use your rewards according to your needs. "
          typeIconWithContentCenterWidth="unset"
          typeIconWithContentCenterFlex="1"
        />
        <TypeIconWithContentCenter
          baseFeatureIcon="/base-feature-icon6.svg"
          headline="Power to you"
          description="We will help you understand how cards, rewards can help your pocket"
          typeIconWithContentCenterWidth="unset"
          typeIconWithContentCenterFlex="1"
        />
        <TypeIconWithContentCenter
          baseFeatureIcon="/base-feature-icon7.svg"
          headline="User friendly"
          description="Simple user interface to focus on what matters the most"
          typeIconWithContentCenterWidth="unset"
          typeIconWithContentCenterFlex="1"
        />
      </div>
      <div className="resp-flex-list-items-wrapper overflow-hidden flex flex-row items-center justify-start gap-[30px]">
        <TypeIconWithContentCenter
          baseFeatureIcon="/base-feature-icon8.svg"
          headline="Know it all"
          description={`Get information about your loyalty program & its usage.`}
          typeIconWithContentCenterWidth="384px"
          typeIconWithContentCenterFlex="unset"
        />
        <TypeIconWithContentCenter
          baseFeatureIcon="/base-feature-icon9.svg"
          headline="Leave it to us"
          description="We will remember things for you so you don’t have to. "
          typeIconWithContentCenterWidth="384px"
          typeIconWithContentCenterFlex="unset"
        />
        <TypeIconWithContentCenter
          baseFeatureIcon="/base-feature-icon10.svg"
          headline="Live Dreams"
          description={`Achieve your goals of flying business class, owning an iPhone & more.`}
          typeIconWithContentCenterWidth="384px"
          typeIconWithContentCenterFlex="unset"
        />
      </div>
    </div>
  );
};

export default FeaturesContainer;
