export default function PrivacyPolicy() {
  return (
    <div className="max-w-4xl p-3 mx-auto bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold">Privacy Policy</h1>
      <p>
        We at Magnify (“the Company/Magnify/We/Us”) are committed to protecting
        the privacy and security of your personal information. Our goal is to
        maintain your trust and confidence when handling your personal
        information.
      </p>
      <p>
        This Privacy Policy explains how we collect, use, process, and disclose
        information about you. By using our website/app, you consent to the
        terms of our privacy policy (“Privacy Policy”) in addition to our Terms
        of Use. We encourage you to read this Privacy Policy regarding the
        collection, use, and disclosure of your information from time to time to
        keep yourself updated with the changes &amp; updates that we make to
        this Policy.
      </p>
      <p>
        This Privacy Policy describes our privacy practices for all
        websites/apps, products, and services that are linked to it. However,
        this policy does not apply to those affiliates and partners that have
        their own privacy policy. In such situations, we recommend that you read
        the privacy policy on the applicable site. Any capitalized terms not
        defined hereunder shall hold the same definition as provided under the
        Terms of Use.
      </p>
      <ol className="pl-0 list-none ">
        <li>
          <h2>Information we collect</h2>
          <p>
            The following information is collected by or on behalf of Magnify:
          </p>
          <ol className="pl-0 ml-6 list-none">
            <li>
              <h3 className="text-lg font-semibold ">
                Information provided by You
              </h3>
              <p>
                We receive and store any information you enter on our Website or
                give us in any other way. You can choose not to provide certain
                information but then you might not be able to take advantage of
                many of our services and features. Typically, as a registration
                process or later stage, we ask for details like your name,
                contact number, address, PAN or any other Government
                identification document, Debit/Credit card details, reward
                points information, airline/hotel partners account detail, and
                any other detail relevant to provide our services.
              </p>
            </li>
            <li>
              <h3 className="text-lg font-semibold ">
                Information created when You use our Website/App
              </h3>
              <p>
                We receive and store certain types of information whenever you
                interact with us. For example, we use "cookies" and we obtain
                certain types of information including but not limited to, when
                your web browser accesses the Website or advertisements and
                other content served by or on behalf of the Website on other
                websites. We may also receive/store information about your
                location and your mobile device, including a unique identifier
                for your device, device operating system, manufacturer and model
                of device, etc. On the Android app, Magnify requires access to
                additional information such as SMS. Prior to accessing any such
                information, explicit consent shall be sought from the User.
                Please note that we only read transactional or promotional SMS
                and do not open, access or read any personal SMS.
                <br />
                In case of both Android and iOS apps, we require access to
                users’s emails also. Our access to the SMS and email account(s)
                is authorized through the email provider’s access mechanism. If
                you permit our App to track your credit Instruments ’accounts,
                the App will securely store account details for each of your
                credit Instruments ’accounts, including your sign-in username
                and authorization tokens for tracked accounts.
              </p>
            </li>
            <li>
              <h3 className="text-lg font-semibold ">
                Information collected from Third Parties
              </h3>
              <p>
                We might receive information about You from other sources and
                add it to our account information to further personalize your
                experience on our website/app, and provide certain services that
                cannot be accessed by all users of the website/app. We may
                receive information about you and your activities through
                partnerships, or about your experiences and interactions from
                our partner ad networks.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>How We Use the Information we collect</h2>
          <p>
            We may use, store and process personal information to (1) provide,
            understand, improve and develop the Web Services, (2) create and
            maintain a trusted and safe environment on Magnify (such as
            complying with our legal obligations and compliance with our
            policies) and (3) provide, personalize, measure and improve our
            products & services. All the information collected through different
            channels, allows us to collect statistics about our website/app
            usage and effectiveness, personalize your experience whilst you are
            on our website/app, as well as customize our interactions with you
            and also assist in business development to enhance and expand the
            scope of the Web Services. The following paragraphs describe in more
            detail how we use your personal information.
          </p>
          <ol className="pl-0 ml-6 list-none">
            <li>
              <h3 className="text-lg font-semibold ">
                Providing, understanding & improving Web/App Services
              </h3>
              <p>
                Any information provided by a User will be used solely for
                creating and updating your Account and processing your
                transaction(s) or for any other purposes for which you have
                granted access to such information to us, based on your
                interaction with the Magnify website/app. All personal
                information provided by you in our website/app which has been
                duly received and accepted by us will be used solely for the
                purpose of processing a transaction on the website/app. To
                enable such process, we may share information with authorized
                third parties, for instance, our business partners, financial
                teams/institutions, or postal/government authorities involved in
                fulfilment of said transactions, if any. In connection with a
                transaction, we may also contact you as part of our customer
                satisfaction surveys or for market research purposes. We may use
                the information collected to perform internal operations
                necessary to provide our services, including to troubleshoot
                software bugs and operational problems, to conduct data
                analysis, testing and research and to monitor and analyze usage
                and activity trends. We process this personal information for
                these purposes given our legitimate interest in improving the
                Web Services.
              </p>
            </li>
            <li>
              <h3 className="text-lg font-semibold">
                How we use information collected via SMS and Email
              </h3>
              <p>
                Collection of SMS and Email data is critical to the core
                functionality of our app which is Credit Card Recommendations.
                We only read and store your transactional and promotional
                Email/SMS. We do not open, read or access any personal
                SMS/emails. From SMS/Emails we are able to get meaningful
                information like your total spends, spending pattern, income,
                and interests. Using which our algorithm spits out a set of
                credit cards that are perfect for you. The Company also employs
                automated processes for accessing and analysing information
                provided by you; where we may need to use our algorithm to
                access a password-protected document utilizing information
                provided by you.
              </p>
            </li>
            <li>
              <h3 className="text-lg font-semibold">
                Safety, security and resolution of issues
              </h3>
              <p>
                We may use the personal information to create and maintain a
                safe environment and use the same to detect and prevent fraud,
                spam, abuse, security incidents and other harmful activity. We
                use the information we collect (including recordings of customer
                support calls) to assist you when you contact our customer
                support services to investigate & resolve your queries, monitor
                and improve our customer support responses. Certain online
                transactions may involve us calling you. They may also involve
                online chats. Please be aware that it is our general practice to
                monitor and, in some cases, record such interactions for staff
                training or quality assurance purposes or to retain evidence of
                a particular transaction or interaction. We intend to protect
                your personal information and to maintain its accuracy as
                confirmed by you. Magnify implements reasonable physical,
                administrative, and technical safeguards to help us protect your
                personal information from unauthorized access, use, and
                disclosure. For example, we encrypt all sensitive personal
                information when we transmit such information over the internet.
                We blend security at multiple steps within our products with
                state-of-the-art technology to ensure our systems maintain
                strong security measures. The overall data and privacy security
                design allows us to defend our systems ranging from low hanging
                issue up to sophisticated attacks.
              </p>
            </li>
            <li>
              <h3 className="text-lg font-semibold ">
                Sharing &amp; Disclosure of data with Third Parties
              </h3>
              <p>
                As required by law, at times we might be required to disclose
                your personal information including transactional and financial
                information to relevant authorities. In some cases, when we
                believe that such disclosure is necessary to protect our rights,
                or the rights of others, or to comply with a judicial
                proceeding, court order, or legal process served on our
                website/app we would share such information pursuant to a lawful
                request from law enforcement agencies. You agree and consent to
                receive all communications at the mobile number provided, even
                if this mobile number is registered under DND/NCPR list under
                TRAI regulations. And for that purpose, you further authorise
                Company to share/disclose the information to any third party
                service provider or any affiliates, group companies, their
                authorised agents or third party service providers. We may
                disclose information that identifies you at an individual level
                and which we have collected on our website/app, to other
                affiliate entities and partners that are not acting as our
                suppliers or business partners. Except as described in this
                Privacy Policy, we will only do so with your prior consent. For
                the sake of clarity, Magnify does not sell or lease such
                information. Subject to your express permission to use the
                information as described herein, the information we have about
                you, may be used by us for marketing purposes. This consent is
                purely voluntary, and you may at any time choose not to receive
                marketing materials from us, by indicating so when we call you,
                or by contacting us directly. Further, if you want to remove
                your contact information from all our lists, please send an
                email request to &nbsp;
                <a data-fr-linked="true" href="mailto:contact@magnify.club">
                  contact@magnify.club
                </a>
                &nbsp; Magnify does not deliver third party online
                advertisements on the website/app but we may advertise our
                activities and organizational goals on other websites/apps. We
                may collaborate with other website/app operators as well as
                network advertisers to do so. We request you to read and
                understand such concerned third party privacy policies to
                understand their practices relating to advertising, including
                what type of information they may collect about your internet
                usage. Magnify does not provide any information relating to your
                usage to such website/app operators or network advertisers.
                During your use of the web/app services, you may come across
                links to third party websites/apps that are not affiliated with
                Magnify. Magnify is not responsible for the privacy practices or
                the content of those other websites/apps, or for any acts/
                omissions by such third parties in the course of your
                transaction with them.
              </p>
            </li>
          </ol>
          <li>
            <h2>Account Deletion</h2>
            <p>
              We provide all our Users an option to request the deletion of all
              such information related to the specific card/account including
              but not limited to profile information, card data, transaction
              details, referrals details, google oauth sessions etc. that we are
              not required to retain. In certain circumstances, we may be unable
              to delete your account, such as if there is any outstanding
              dispute or unresolved claims pending on your card/account.
              However, upon resolution of the issue preventing deletion, the
              information is immediately deleted and can ’t be recovered
              thereafter. Please note that we may retain certain information if
              necessary, for our own legitimate business interests such as fraud
              prevention and enhancing users ’safety and security OR to fulfil
              our legal obligations and compliance. Users can raise the request
              to delete the account by heading to `My Account`. Select reason to
              delete & give their valuable feedback.
            </p>
          </li>
          <li>
            <h2>Cookies</h2>
            <p>
              We use data collection devices such as “cookies ”, etc. on certain
              parts of the Web to help analyze the Web Services, user
              interaction with the Web, measure promotional effectiveness, and
              promote trust and safety. For the sake of clarity, “cookies ”are
              small files placed on your device hard-drive/storage that assist
              us in providing the Web Services. Please be informed that Magnify
              offers certain features via the Web that are only available
              through the use of a “cookie ”. We also use cookies to allow you
              to enter a particular password less frequently during a session.
              Cookies also help us obtain information that assists in curating
              the Services more targeted to your interests. You are always free
              to decline our cookies if your device permits, although in that
              case you may not be able to use certain features on the
              website/app and you may be required to provide a password more
              frequently during a session.
            </p>
          </li>
          <li>
            <h2>Compliances</h2>
            <p>
              Magnify &#39;s use of information received from Google APIs will
              adhere to Google API Services User Data Policy, including the
              Limited Use requirements.
            </p>
          </li>
          <li>
            <h2>Changes to Privacy Policy</h2>
            <p>
              Magnify reserves the right to change this policy from time to
              time. Any changes shall be effective immediately upon the posting
              of the revised Privacy Policy. We encourage you to periodically
              review this page for latest information on our privacy practices.
            </p>
          </li>
          <li>
            <h2>Privacy Questions and Access</h2>
            <p>
              If you have questions, concerns, or suggestions regarding our
              Privacy Policy, we can be reached using the contact information on
              our “Contact Us ”page or at{" "}
              <a data-fr-linked="true" href="mailto:contact@magnify.club">
                contact@magnify.club
              </a>
              . In certain cases, you may have the ability to view or edit your
              personal information online. In the event your information is not
              accessible online, and you wish to obtain a copy of particular
              information you provided to Magnify, or if you become aware the
              information is incorrect and you would like us to correct it,
              please contact us immediately; we will verify your identity and
              respond to your request.
            </p>
          </li>
          <li>
            <h2>Your Consent</h2>
            <p>
              By accessing our Website and/or our mobile application and using
              it on a continued basis, you expressly consent and confirm to us
              collecting, maintaining, using, processing and disclosing your
              personal and user information in accordance with this Privacy
              Policy.
            </p>
          </li>
        </li>
      </ol>
    </div>
  );
}
