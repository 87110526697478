export default function TermsAndConditions() {
  return (
    <>
      <div className="max-w-4xl p-3 mx-auto bg-white rounded-lg shadow-md ">
        <h1>General Terms of Use</h1>
        <p>
          Welcome to www.magnify.club(“<strong>Website</strong>”).
          <br />
          <br />‍
          <strong>
            This document/agreement/understanding is a computer-generated
            electronic record published in terms of Rule 3 of the Information
            Technology (Intermediaries Guidelines) Rules, 2011 read with
            Information Technology Act, 2000 and does not require any physical
            or digital signatures.
            <br />
            <br />‍
          </strong>
          Before you may use the Website, you must read all of these General
          Terms of Use (“<strong>Terms</strong>”) herein and the{" "}
          <a href="/privacy-policy">Privacy Policy</a> provided on the Website.
          By using Magnify&#x27;s products, software, services, and the Website
          or by availing any products offered by Magnify’s partner institutions
          or third parties (“
          <strong>Services”</strong>), you understand and agree that Magnify
          will treat your use of the Services as acceptance of these Terms from
          such point of usage. You may not use the Services if you do not accept
          the Terms. If you do not agree to be bound by these Terms and the
          Privacy Policy, you may not use the Website in any way. It is strongly
          recommended for you to return to this page periodically to review the
          most current version of the Terms in force. Magnify reserves the right
          at any time, at its sole discretion, to change or otherwise modify the
          Terms without prior notice, and your continued access or use of this
          Website signifies your assent/ratification of the updated or modified
          Terms. If you object to these Terms or any subsequent modifications to
          these Terms in any way, your only recourse is to immediately terminate
          use of the Website<strong>. </strong>We may require you to agree to
          additional terms (if any) between you and Magnify in connection with
          specific services that you may avail from time to time. The service
          provided by Magnify through Website is available and appropriate only
          for use in India.
        </p>
        <div>
          <h2>Proprietary rights</h2>
          <div>
            You acknowledge and agree that Magnify owns all rights, title and
            interest in and to the Services, including any intellectual property
            rights which subsist in the Services (whether registered or not).
            You further acknowledge that the Services may contain information
            which is designated confidential by Magnify and that you shall not
            disclose such information without Magnify&#x27;s prior written
            consent.
            <br />
            <br />
            Magnify grants you a limited license to access and make personal use
            of the Website and the Services. This license does not confer any
            right to download, copy, create a derivative work from, modify,
            reverse engineer, reverse assemble or otherwise attempt to discover
            any source code, sell, assign, sub-license, grant a security
            interest in or otherwise transfer any right in the Services. You do
            not have the right to use any of Magnify&#x27;s trade names,
            trademarks, service marks, logos, domain names, and other
            distinctive brand features. You do not have the right to remove,
            obscure, or alter any proprietary rights notices (including
            trademark and copyright notices), which may be affixed to or
            contained within the Services. You will not copy or transmit any of
            the Services.
          </div>
        </div>
        <div>
          <h2>Usage of the website</h2>
          <div>
            The Website is intended for personal and non-commercial use. You
            shall register to become a member of the Website only if you are of
            the age of 18 or above and can enter into binding contracts as per
            applicable laws. You are responsible for maintaining the secrecy of
            your passwords, login and account information. You will be
            responsible for all use of the Website by you and anyone using your
            password and login information (with or without your permission).
            <br />
            <br />
            You also agree to provide true, accurate, current and complete
            information about yourself as and when prompted by the Website. If
            you provide any information that is untrue, inaccurate, not updated
            or incomplete (or becomes untrue, inaccurate, not updated or
            incomplete), or Magnify has reasonable grounds to suspect that such
            information is untrue, inaccurate, not updated or incomplete,
            Magnify shall have the right to suspend or terminate your account
            and/or refuse any and all current or future use of the Website (or
            any portion thereof) or Services in connection thereto.
            <br />
            <br />
            By making use of the Website, and furnishing your personal/contact
            details, you hereby agree that you are interested in knowing more or
            availing and/or purchasing various products, services, offers,
            campaigns or other promotional material that Magnify or any other
            third party may offer/provide/share/send you from time to time
            through any means including but not limited to telephone, SMS (short
            messaging service), Whatsapp or any other messaging service/mobile
            application or any other physical, electronic or digital means/mode
            . You hereby agree that Magnify may contact you either
            electronically or through phone, to understand your interest in the
            selected products and Services and to fulfil your demand or complete
            your application. Further you also expressly agree and authorize
            Magnify and its partners, service providers, vendors and other third
            parties to contact you for the purpose of offering or inviting your
            interest in availing other products or services offered by third
            parties, or for sending other marketing campaigns, offers, or any
            other information either on the Website or through other means
            including but not limited to telephone, SMS (short messaging
            service), Whatsapp or any other messaging service/mobile application
            or any other physical, electronic or digital means/mode.
            <br />
            <br />
            You specifically understand and agree that by using the Website you
            authorize Magnify , its affiliates, partners and third parties to
            contact you for any follow up calls in relation to the Services
            provided through the Website and for offering or inviting your
            interest in availing any other product or service offered by Magnify
            or such third parties. You agree and consent to receive
            communications relating to all of the above on your phone/mobile
            number provided by you on the Website and expressly waive any
            registration or preference made under DND/NCPR list under the
            applicable TRAI regulations.
            <br />
            <br />
            You agree and authorize Magnify to share your information, with its
            group companies, vendors, service providers of Magnify and other
            third parties, in so far as required for marketing
            purposes/offering/cross-selling various products and services and/or
            to provide you with various value-added services, in association
            with the Services selected by you or generally otherwise. You agree
            to receive communications through telephone and/or SMS, from Magnify
            or third parties. You also agree that Magnify reserves the right to
            make your details available to its partner banks/financial
            institutions, vendors, service providers, business partners, agents
            or any other third party and that you may be contacted by such
            partners and/or the third party for information through telephone
            and/or SMS. If you request not to receive such
            communication/marketing material any further, it shall be applicable
            prospectively only and shall not apply in respect to your data
            already shared by Magnify with its partners upon your prior consent.
            In case you do not wish to receive any such communication/marketing
            materials from our partner banks/ financial institutions/third
            parties, we request you to kindly get in touch with them
            independently, without involving Magnify.
            <br />
            <br />
            You agree and acknowledge that for undertaking any financial
            transaction through the Website, Magnify may undertake
            client/customer due diligence measurers and seek mandatory
            information required for Know-Your-Customer (“KYC”) purpose which as
            a customer you are obliged to give, while facilitating your request
            of loan/credit card/mutual fund and other financial product
            requirements with the banks/financial institutions, in accordance
            with applicable law and regulations. Magnify may obtain sufficient
            information to establish, to its satisfaction or the banks/financial
            institutions, the identity of each new customer/user, and to
            ascertain the purpose of the intended nature of relationship between
            you and the bank/financial institution. You agree and acknowledge
            that Magnify can undertake enhanced due diligence measures
            (including any documentation), to satisfy itself relating to
            customer due diligence requirements in line with the requirements
            and obligations under applicable laws and regulations.
            <br />
            <br />
            The usage of the Website may also require you to provide consent for
            keying in your Personal Information (“<strong>PI</strong>”)
            (including but not limited to any personal data or sensitive
            personal data as defined under applicable law) or to authorize
            Magnify to derive your data/information from any source or public
            registry, as may be necessary to complete your profile or your
            application on the Website, conduct due diligence on you, assessing
            your eligibility for the products/services, undertaking KYC checks
            by Magnify or any other third party and/or to process your
            application through this Website. Your PI may also be used or shared
            with third parties, including but not limited to our vendors,
            service providers, analytics and research partners in India and
            abroad, with the sole objective of making your experience on the
            Website better, faster, friction-less and paperless to the extent
            possible.. However, Magnify shall adhere to best industry practices
            including information security, data protection and privacy law
            while processing such applications. However, Magnify shall not be
            liable to you against any liability or claims which may arise out of
            such transactions as any such PI is being collected, used, processed
            and shared with your explicit consent.
            <br />
            <br />
            You shall not sell Magnify’s Services, information, or software
            associated with or derived from it or use any robot, spider, other
            automatic device, or manual process to monitor or copy the Website
            and or its content or interfere or disrupt the Website; take any
            action that imposes an unreasonably or disproportionately large load
            on Magnify’s infrastructure/network or use any device, or interfere
            or attempt to interfere with the Magnify Services or forge headers
            or manipulate identifiers or other data in order to disguise the
            origin of any content transmitted through Magnify or to manipulate
            your presence on the Website.
            <br />
            <br />
            You may only use the Website to search for and to apply for credit
            cards or other financial products as may be displayed on the Website
            from time to time and you shall not use the Website to make a
            fraudulent application for any products listed on the Website. You
            agree not to use the Website for any purpose that is unlawful,
            illegal or forbidden by these Terms, or any local laws that might
            apply to you. Since the Website is in operation in India, while
            using the Website, you shall agree to comply with laws that apply to
            India and your own country (in case of you being a foreign
            national). We may, at our sole discretion, at any time and without
            advance notice or liability, suspend, terminate or restrict your
            access to all or any component of the Website. Magnify does not
            provide any Service to individuals resident in the European Union,
            European Economic Area, Switzerland, Guernsey and Jersey.
            <br />
            <br />
            You are prohibited from posting or transmitting to or through this
            Website: (i) any unlawful, threatening, libellous, defamatory,
            obscene, pornographic, or other material or content that would
            violate rights of publicity and/or privacy or that would violate any
            law; (ii) any commercial material or content (including, but not
            limited to, solicitation of funds, advertising, or marketing of any
            good or services); and (iii) any material or content that infringes,
            misappropriates or violates any copyright, trademark, patent right
            or other proprietary right of any third party. You shall be solely
            liable for any damages resulting from any violation of the foregoing
            restrictions, or any other harm resulting from your posting of
            content to this Website.
          </div>
        </div>
        <div>
          <h2>Privacy Policy</h2>
          <div>
            By using the Website, you hereby consent to the use of your
            information as we have outlined in our{" "}
            <a href="/privacy-policy">Privacy Policy</a>.
          </div>
        </div>
        <div>
          <h2>Third party links/offers</h2>
          <div>
            This Website may provide links to other websites or resources. Since
            Magnify has no control over such third party websites and resources,
            you acknowledge and agree that Magnify is not responsible for the
            availability of such external sites or resources, and does not
            endorse and is not responsible or liable for any content,
            advertising, products or other materials on or available from such
            sites or resources. You further acknowledge and agree that Magnify
            shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such content, goods or services
            available on or through any such site or resource. Your interaction
            with any third party accessed through the Website is at your own
            risk, and Magnify will have no liability with respect to the acts,
            omissions, errors, representations, warranties, breaches or
            negligence of any such third parties or for any personal injuries,
            death, property damage, or other damages or expenses resulting from
            your interactions with the third parties.
          </div>
        </div>
        <div>
          <h2>Our partners</h2>
          <div>
            Display of credit card or other financial products, offered by third
            parties, on the Website does not in any way imply, suggest, or
            constitute any sponsorship, recommendation, opinion, advice or
            approval of Magnify in favour/against such third parties or their
            products. You agree that Magnify is in no way responsible for the
            accuracy, timeliness or completeness of information it may obtain
            from these third parties.
            <br />
            <br />
            Your interaction with any third party accessed through the Website
            is at your own risk, and Magnify will have no liability with respect
            to the acts, omissions, errors, representations, warranties,
            breaches or negligence of any such third parties or for any personal
            injuries, death, property damage, or other damages or expenses
            resulting from your interactions with the third parties.
            <br />
            <br />
            You agree and acknowledge that the credit shall be at the sole
            discretion of Magnify’s financial partners (banks, credit card
            companies) while making any application through the Website for a
            financial product offered by such financial partners; Magnify shall
            not be held liable for any delay, rejection or approval of any
            application made through its Website.
          </div>
        </div>
        <div>
          <h2>Disclaimer of warranty</h2>
          <div>
            The Website and all content and Services provided on the Website are
            provided on an &quot;<strong>as is</strong>&quot; and &quot;
            <strong>as available</strong>&quot; basis. Magnify expressly
            disclaims all warranties of any kind, whether express or implied,
            including, but not limited to, the implied warranties of
            merchantability, fitness for a particular purpose, title,
            non-infringement, and security and accuracy, as well as all
            warranties arising by usage of trade, course of dealing, or course
            of performance. Magnify makes no warranty, and expressly disclaims
            any obligation, that: (a) the content will be up-to-date, complete,
            comprehensive, accurate or applicable to your circumstances; (b) The
            Website will meet your requirements or will be available on an
            uninterrupted, timely, secure, or error-free basis; (c) the results
            that may be obtained from the use of the Website or any Services
            offered through the Website will be accurate or reliable; or (d) the
            quality of any products, services, information, or other material
            obtained by you through the Website will meet your expectations.
            <br />
            <br />
            Some of the content on the Website is aimed at spreading general
            awareness about various financial and ancillary services but does
            not constitute any investment advice, and the same shall not be
            construed as any solicitation, endorsement, advertising, marketing
            or promotion of such services by Magnify. These content and/or
            information are derived from publicly available sources and Magnify
            cannot verify or confirm the genuineness, authenticity or veracity
            of such information.
            <br />
            <br />
            Magnify, either directly, or through its business partners/vendors
            may enter into agreements with third party payment gateway
            aggregators and financial institutions authorized by the Reserve
            Bank of India for collection, refund and remittance and to
            facilitate payment between you, Magnify and its business partners,
            as the case may be. Magnify shall initiate the remittance of the
            payments made by you and the date of completion of transaction shall
            be after the products are delivered to the you or after the Services
            are rendered to you and such other additional time as may be agreed
            between Website and its business partners, as the case may be.
            <br />
            <br />
            While availing any of the payment method/s available on the Website,
            Magnify will not be responsible or assume any liability, whatsoever
            in respect of any loss or damage arising directly or indirectly to
            you due to:
          </div>
          <h3>1. Lack of authorization for any transaction/s, or</h3>
          <h3>2. Any payment issues arising out of the transaction, or</h3>
          <h3>
            3. Illegitimacy of the payment methods (credit/debit card frauds
            etc.) being used by a you;
          </h3>
          <h3>4. Decline of transaction for any other reason(s)</h3>
          <div>
            Notwithstanding anything contained herein, the Website reserves the
            right to conduct additional verification for security or other
            reasons if it is not satisfied with the creditability of you/your
            transaction.
            <br />
            <br />
            Use of the payment facilities provided by the Website shall not
            render the Website liable or responsible for the non-delivery,
            non-receipt, non-payment, damage, breach of representations and
            warranties, non-provision of after sales or warranty services or
            fraud as regards the products or services listed on the Website. The
            Website shall not be responsible for any damages, interests or
            claims arising from not processing a transaction.
            <br />
            <br />
            You hereby agree to provide accurate information, such as
            credit/debit information for purchasing any Service or product on or
            through the Website. You further warrant that you shall not use
            payment information or instrument that is not lawfully owned by you.
            In addition to this terms of use, the terms and conditions of the
            bank or other financial institution shall also be applicable to
            every user. The Website disclaims any liability arising out of
            declining of payment by such bank or financial institution.
            <br />
            <br />
            No deliveries of the products/services shall be made outside the
            territorial boundaries of India.
          </div>
        </div>
        <div>
          <h2>Limitation of liability</h2>
          <div>
            Magnify (including its officers, directors, employees,
            representatives, affiliates, and providers) will not be responsible
            or liable for (a) any injury, death, loss, claim, act of god,
            accident, delay, or any direct, special, exemplary, punitive,
            indirect, incidental or consequential damages of any kind (including
            without limitation lost profits or lost savings), whether based in
            contract, tort, strict liability or otherwise, that arise out of or
            is in any way connected with (i) any failure or delay (including
            without limitation the use of or inability to use any component of
            the Website), or (ii) any use of the Website or content, or (iii)
            the performance or non-performance by us or any provider, even if we
            have been advised of the possibility of damages to such parties or
            any other party, or (b) any damages to or viruses that may infect
            your computer equipment or other property as the result of your
            access to the Website or your downloading of any content from the
            Website. The Website may provide links to other third-party
            websites. However, since Magnify has no control over such
            third-party websites, you acknowledge and agree that Magnify is not
            responsible for the availability of such third-party websites and
            does not endorse and is not responsible or liable for any content,
            advertising, products or other materials on or available from such
            third-party websites. You further acknowledge and agree that Magnify
            shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such content, goods or services
            available on or through any such third-party websites. Such
            third-party website may have separate terms and conditions and
            privacy policy, and which are independent of Magnify and therefore,
            we advise you to read the terms and conditions available on such
            website before you access any such third-party website.
          </div>
        </div>
        <div>
          <h2>Indemnity</h2>
          <div>
            You agree to indemnify and hold Magnify (and its officers,
            directors, agents and employees) harmless from any and against any
            claims, causes of action, demands, recoveries, losses, damages,
            fines, penalties or other costs or expenses of any kind or nature,
            including reasonable attorneys&#x27; fees, or arising out of or
            related to your breach of this Terms, your violation of any law or
            the rights of a third party, or your use of the Website.
          </div>
        </div>
        <div>
          <h2>Waiver</h2>
          <div>
            Any failure or delay by Magnify to enforce or exercise any provision
            of these Terms, or any related right, shall not constitute a waiver
            by Magnify of that provision or right. The exercise of one or more
            of Magnify’s rights hereunder shall not be a waiver of, or preclude
            the exercise of, any rights or remedies available to Magnify under
            these Terms or in law or at equity. Any waiver of any provision
            shall only be effective if made in writing and executed by a duly
            authorized officer of Magnify.
          </div>
        </div>
        <div>
          <h2>Force majeure</h2>
          <div>
            If performance of Services/Website by Magnify is prevented,
            restricted, delayed or interfered with by reason of labour disputes,
            strikes, acts of God, floods, lightning, severe weather, shortages
            of materials, rationing, inducement of any virus, malware, trojan or
            other disruptive mechanisms, any event of hacking or illegal usage
            of the Website, utility or communication failures, earthquakes, war,
            revolution, acts of terrorism, civil commotion, acts of public
            enemies, blockade, embargo or any law, order, proclamation,
            regulation, ordinance, demand or requirement having legal effect of
            any government or any judicial authority or representative of any
            such government, or any other act whatsoever, whether similar or
            dissimilar to those referred to in this clause, which are beyond the
            reasonable control of Magnify and could not have been prevented by
            reasonable precautions, then Magnify shall be excused and discharged
            from such performance to the extent of and during the period of such
            force majeure event, and such non-performance shall, in no manner
            whosoever, amount to a breach by Magnify of its obligations herein
            or incur any legal liability on Magnify.
          </div>
        </div>
        <div>
          <h2>Additional terms</h2>
          <div>
            If performance of Services/Website by Magnify is prevented,
            restricted, delayed or interfered with by reason of labour disputes,
            strikes, acts of God, floods, lightning, severe weather, shortages
            of materials, rationing, inducement of any virus, malware, trojan or
            other disruptive mechanisms, any event of hacking or illegal usage
            of the Website, utility or communication failures, earthquakes, war,
            revolution, acts of terrorism, civil commotion, acts of public
            enemies, blockade, embargo or any law, order, proclamation,
            regulation, ordinance, demand or requirement having legal effect of
            any government or any judicial authority or representative of any
            such government, or any other act whatsoever, whether similar or
            dissimilar to those referred to in this clause, which are beyond the
            reasonable control of Magnify and could not have been prevented by
            reasonable precautions, then Magnify shall be excused and discharged
            from such performance to the extent of and during the period of such
            force majeure event, and such non-performance shall, in no manner
            whosoever, amount to a breach by Magnify of its obligations herein
            or incur any legal liability on Magnify.
          </div>
        </div>
      </div>
    </>
  );
}
