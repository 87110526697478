export const airports = [
  { city: "Mumbai", code: "BOM", country: "India" },
  { city: "Delhi", code: "DEL", country: "India" },
  { city: "Bengaluru", code: "BLR", country: "India" },
  { city: "Chennai", code: "MAA", country: "India" },
  { city: "Dibrugarh", code: "DIB", country: "India" },
  { city: "Kolkata", code: "CCU", country: "India" },
  { city: "Hyderabad", code: "HYD", country: "India" },
  { city: "Ahmedabad", code: "AMD", country: "India" },
  { city: "Amritsar", code: "ATQ", country: "India" },
  { city: "Udaipur", code: "UDR", country: "India" },
  { city: "Chandigarh", code: "IXC", country: "India" },
  { city: "Jaipur", code: "JAI", country: "India" },
  { city: "Dehradun", code: "DED", country: "India" },
  { city: "Varanasi", code: "VNS", country: "India" },
  { city: "Bhubaneswar", code: "BBI", country: "India" },
  { city: "Coimbatore", code: "CJB", country: "India" },
  { city: "Kochi", code: "COK", country: "India" },
  { city: "Thiruvananthapuram", code: "TRV", country: "India" },
  { city: "Jammu", code: "IXJ", country: "India" },
  { city: "Srinagar", code: "SXR", country: "India" },
  { city: "Leh", code: "IXL", country: "India" },
  { city: "Bagdogra", code: "IXB", country: "India" },
  { city: "Patna", code: "PAT", country: "India" },
  { city: "Lucknow", code: "LKO", country: "India" },
  { city: "Ranchi", code: "IXR", country: "India" },
  { city: "Raipur", code: "RPR", country: "India" },
  { city: "Goa", code: "GOI", country: "India" },
  { city: "Pune", code: "PNQ", country: "India" },
  { city: "Guwhati", code: "GAU", country: "India" },
  { city: "Mopa", code: "GOX", country: "India" },
  { city: "Port Blair", code: "IXZ", country: "India" },
  { city: "Frankfurt", code: "FRA", country: "Germany" },
  { city: "Paris", code: "CDG", country: "France" },
  { city: "Dubai", code: "DXB", country: "UAE" },
  { city: "Dammam", code: "DMM", country: "Saudi Arabia" },
  { city: "Doha", code: "DOH", country: "Qatar" },
  { city: "Abu Dhabi", code: "AUH", country: "UAE" },
  { city: "Jeddah", code: "JED", country: "Saudi Arabia" },
  { city: "Mauritius", code: "MRU", country: "Mauritius" },
  { city: "Muscat", code: "MCT", country: "Oman" },
  { city: "Male", code: "MLE", country: "Maldives" },
  { city: "Colombo", code: "CMB", country: "Sri Lanka" },
  { city: "Singapore", code: "SIN", country: "Singapore" },
  { city: "Bangkok", code: "BKK", country: "Thailand" },
  { city: "Dhaka", code: "DAC", country: "Bangladesh" },
  { city: "Kathmandu", code: "KTM", country: "Nepal" },
  { city: "London", code: "LHR", country: "United Kingdom" },
  { city: "Denpasar", code: "DPS", country: "Indonesia" },
  { city: "Hong Kong", code: "HKG", country: "China" },
];
