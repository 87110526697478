import "./page-not-found.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      navigate("/");
    }
  }, [seconds]);

  return (
    <div className="flex flex-col items-start h-screen md:flex-row">
      <div className="flex items-center justify-center w-[100%] pb-[15rem] pt-[10rem] flex-col bg-[#eae9f0] px-[1rem] sm:px-[0rem]">
        <div className="flex sm:flex-row flex-col items-center justify-center mb-[3rem]">
          <img
            className="tinted-image lg:h-[8rem] sm:h-[5rem] h-[3rem]"
            alt="plane"
            src="/plane.png"
          />
          <span className="md:ml-[5rem] sm:ml-[3rem] ml-[0rem]">
            <p className="mb-5 lg:text-[2rem] text-[1.5rem] text-[#363636] text-center">
              404: Miles Off Course
            </p>
          </span>
        </div>

        <div className="flex flex-col items-center justify-center">
          <span className="lg:text-[1.5rem] text-[1rem] text-center">
            Let's get you back to your next great reward.
          </span>
          <div className="mt-4 text-4xl lg:text-[1.3rem] text-[1rem] text-center text-[#7165E3]">
            Redirecting to{" "}
            <a href="/" className="text-[#7165E3]">
              home
            </a>{" "}
            in {seconds} seconds...
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
