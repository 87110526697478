import { FunctionComponent, useMemo, type CSSProperties } from "react";

type TypeIconWithContentCenterType = {
  baseFeatureIcon?: string;
  headline?: string;
  description?: string;

  /** Style props */
  typeIconWithContentCenterWidth?: CSSProperties["width"];
  typeIconWithContentCenterFlex?: CSSProperties["flex"];
};

const TypeIconWithContentCenter: FunctionComponent<
  TypeIconWithContentCenterType
> = ({
  baseFeatureIcon,
  headline,
  description,
  typeIconWithContentCenterWidth,
  typeIconWithContentCenterFlex,
}) => {
  const typeIconWithContentCenterStyle: CSSProperties = useMemo(() => {
    return {
      width: typeIconWithContentCenterWidth,
      flex: typeIconWithContentCenterFlex,
    };
  }, [typeIconWithContentCenterWidth, typeIconWithContentCenterFlex]);

  return (
    <div
      className="resp-flex-list-item rounded-lg w-96 flex flex-col items-center justify-start gap-[24px] text-center text-11xl text-neutral-900 font-body-lg-medium"
      style={typeIconWithContentCenterStyle}
    >
      <img
        className="relative w-8 h-8 overflow-hidden shrink-0"
        alt=""
        src={baseFeatureIcon}
      />
      <div className="self-stretch overflow-hidden flex flex-col items-center justify-start py-0 px-4 gap-[8px]">
        <b className="self-stretch relative leading-[40px]">{headline}</b>
        <div className="self-stretch relative text-base leading-[24px] text-neutral-700">
          {description}
        </div>
      </div>
    </div>
  );
};

export default TypeIconWithContentCenter;
