import { FunctionComponent, useMemo, type CSSProperties } from "react";

type ModeliPhoneXType = {
  screen1?: string;
  iPhoneX?: string;

  /** Style props */
  modeliPhoneXWidth?: CSSProperties["width"];
  modeliPhoneXHeight?: CSSProperties["height"];
  modeliPhoneXPosition?: CSSProperties["position"];
};

const ModeliPhoneX: FunctionComponent<ModeliPhoneXType> = ({
  screen1,
  iPhoneX,
  modeliPhoneXWidth,
  modeliPhoneXHeight,
  modeliPhoneXPosition,
}) => {
  const modeliPhoneXStyle: CSSProperties = useMemo(() => {
    return {
      width: modeliPhoneXWidth,
      height: modeliPhoneXHeight,
      position: modeliPhoneXPosition,
    };
  }, [modeliPhoneXWidth, modeliPhoneXHeight, modeliPhoneXPosition]);

  return (
    <div
      className="resp-border-radius shadow-[0px_24px_48px_-12px_rgba(17,_24,_39,_0.25)] w-[430px] h-[860px]"
      style={modeliPhoneXStyle}
    >
      <img
        className="absolute h-[94.42%] w-[87.21%] top-[3.02%] right-[6.28%] bottom-[2.56%] left-[6.51%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src={screen1}
      />
      <img
        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src={iPhoneX}
      />
    </div>
  );
};

export default ModeliPhoneX;
