export default function CancellationRefundPolicy() {
  return (
    <div className="max-w-4xl p-3 mx-auto bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold">Cancellation and Refund Policy</h1>
      <p>
        At Magnify, we strive to provide exceptional services and ensure our
        clients are satisfied with their experience. We offer flexible
        membership plans, at different price points. The pricing may change at
        any time at the sole discretion of Magnify.
      </p>
      <p>
        We understand that our services might not meet everyone's expectations.
        Therefore, we have implemented a "No Questions Asked" refund policy to
        ensure your peace of mind.
      </p>
      <h2 className="text-xl font-semibold">Refund Eligibility:</h2>
      <ul className="pl-0 ml-6 list-disc">
        <li>
          If you are not satisfied with our consultation or any other services
          you have availed, you are eligible for a full refund.
        </li>
        <li>
          The refund request must be made post 15 days and up to 29 days of your
          membership purchase (i.e., between 15 and 29 days after the purchase
          date).
        </li>
      </ul>
      <h2 className="text-xl font-semibold">How to Request a Refund:</h2>
      <ol className="pl-0 ml-6 list-decimal">
        <li>
          Contact our customer service team via email at{" "}
          <a href="mailto:contact@magnify.club">contact@magnify.club</a>.
        </li>
        <li>
          Provide your membership details and the reason for your refund request
          (optional, but appreciated for improving our services).
        </li>
        <li>
          Our team will process your refund promptly, and you will receive a
          confirmation once the refund has been issued.
        </li>
      </ol>
      <h2 className="text-xl font-semibold">Refund Processing:</h2>
      <ol className="pl-0 ml-6 list-disc">
        <li>Refunds will be processed using the original payment method.</li>
        <li>
          It may take up to 7-10 business days for the refund to reflect in your
          account, depending on your bank or payment provider.
        </li>
      </ol>
      <p>
        We value your trust and are committed to providing a satisfactory
        experience with Magnify. If you have any questions or concerns regarding
        our refund policy, please do not hesitate to contact our support team.
      </p>
    </div>
  );
}
