import { FunctionComponent, useMemo, type CSSProperties } from "react";

type TypeTitleDescriptionColuType = {
  sectionTitle?: string;
  description?: string;

  /** Style props */
  typeTitleDescriptionColuBorder?: CSSProperties["border"];
  typeTitleDescriptionColuBoxSizing?: CSSProperties["boxSizing"];
  typeTitleDescriptionColuFlexShrink?: CSSProperties["flexShrink"];
  typeTitleDescriptionColuPadding?: CSSProperties["padding"];
  typeTitleDescriptionColuWidth?: CSSProperties["width"];
  sectionTitleTextAlign?: CSSProperties["textAlign"];
  descriptionTextAlign?: CSSProperties["textAlign"];
};

const TypeTitleDescriptionColu: FunctionComponent<
  TypeTitleDescriptionColuType
> = ({
  sectionTitle,
  description,
  typeTitleDescriptionColuBorder,
  typeTitleDescriptionColuBoxSizing,
  typeTitleDescriptionColuFlexShrink,
  typeTitleDescriptionColuPadding,
  typeTitleDescriptionColuWidth,
  sectionTitleTextAlign,
  descriptionTextAlign,
}) => {
  const typeTitleDescriptionColuStyle: CSSProperties = useMemo(() => {
    return {
      border: typeTitleDescriptionColuBorder,
      boxSizing: typeTitleDescriptionColuBoxSizing,
      flexShrink: typeTitleDescriptionColuFlexShrink,
      padding: typeTitleDescriptionColuPadding,
      width: typeTitleDescriptionColuWidth,
    };
  }, [
    typeTitleDescriptionColuBorder,
    typeTitleDescriptionColuBoxSizing,
    typeTitleDescriptionColuFlexShrink,
    typeTitleDescriptionColuPadding,
    typeTitleDescriptionColuWidth,
  ]);

  const sectionTitleStyle: CSSProperties = useMemo(() => {
    return {
      textAlign: sectionTitleTextAlign,
    };
  }, [sectionTitleTextAlign]);

  const descriptionStyle: CSSProperties = useMemo(() => {
    return {
      textAlign: descriptionTextAlign,
    };
  }, [descriptionTextAlign]);

  return (
    <div
      className="resp-section-title w-[488px] overflow-hidden flex flex-col items-start justify-start gap-[8px] text-left text-29xl text-neutral-900 font-body-lg-medium"
      style={typeTitleDescriptionColuStyle}
    >
      <b
        className="resp-text-large self-stretch relative tracking-[-0.02em] leading-[60px]"
        style={sectionTitleStyle}
      >
        {sectionTitle}
      </b>
      <div
        className="resp-text-medium self-stretch relative text-lg leading-[28px]"
        style={descriptionStyle}
      >
        {description}
      </div>
    </div>
  );
};

export default TypeTitleDescriptionColu;
