import { FunctionComponent } from "react";

const HomeContainer: FunctionComponent = () => {
  return (
    <div className="home-container relative w-[1440px] h-[866px] overflow-hidden shrink-0 z-[0]">
      <div className="absolute top-[-37px] left-[1087px] rounded-[50%] bg-primary-600 w-[406px] h-[406px] opacity-[0.6]" />
      <div className="absolute top-[464px] left-[829px] rounded-[50%] bg-secondary-600 w-[168px] h-[168px] opacity-[0.6]" />
      <div className="absolute top-[-10px] left-[-10px] w-[1460px] flex flex-col items-start justify-start p-2.5 box-border">
        <img
          className="self-stretch relative max-w-full overflow-hidden h-[752px] shrink-0"
          alt=""
          src="/bg.svg"
        />
      </div>
      <img
        className="absolute top-[541.49px] left-[1260px] w-[420px] h-[116.52px]"
        alt=""
        src="/right-band-3.svg"
      />
      <img
        className="absolute top-[508px] left-[828px] w-[612px] h-[140.81px]"
        alt=""
        src="/right-band-2.svg"
      />
      <img
        className="absolute top-[448px] left-[988px] w-[420px] h-[116.52px]"
        alt=""
        src="/right-band-1.svg"
      />
      <img
        className="absolute top-[635.5px] left-[0px] w-[420px] h-[116.52px]"
        alt=""
        src="/left-band-1.svg"
      />
      <img
        className="absolute top-[718.49px] left-[0px] w-[420px] h-[116.52px]"
        alt=""
        src="/left-band-2.svg"
      />
    </div>
  );
};

export default HomeContainer;
