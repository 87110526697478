import SizexlColorTertiaryIcon from "./sizexl-color-tertiary-icon";
import ModeliPhoneX from "./modeli-phone-x";
import AppStoreLinks from "./AppStoreLinks";
interface RewardManagementProps {}
const RewardManagementContainer: React.FC<RewardManagementProps> = () => {
  return (
    <div className="reward-management-container my-0 mx-[!important] absolute top-[152px] left-[112px] flex flex-row items-start justify-start z-[1] text-left text-41xl text-neutral-900 font-body-lg-medium">
      <div className="resp-flex-wrap flex flex-row items-start justify-start gap-[32px]">
        <div className="resp-banner-text w-[696px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-24 pb-0 pr-12 pl-0 box-border gap-[32px]">
          <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[16px]">
            <b className="resp-text-large self-stretch relative tracking-[-0.02em] leading-[72px]">
              The easier way to manage rewards
            </b>
            <div className="resp-text-medium self-stretch relative text-lg leading-[28px] text-neutral-700">
              Keep track of your loyalty points across credit cards, airlines,
              hotels at one place.
            </div>
          </div>
          <div className="flex flex-row items-center justify-start">
            <div className="flex flex-row items-center justify-center">
              <AppStoreLinks showExpDialog={true} />
            </div>
            <SizexlColorTertiaryIcon
              essentialIcon="/essential-icon2.svg"
              label="Watch Video"
              showButtonBase={false}
              essentialIconOverflow="unset"
              essentialIconFlexShrink="unset"
              labelColor="#7165e3"
            />
          </div>
        </div>
        <div className="resp-mobile-screen-box w-[488px] flex flex-col items-center justify-center">
          <ModeliPhoneX
            screen1="/screen1@2x.png"
            iPhoneX="/iphone-x1@2x.png"
            modeliPhoneXWidth="320px"
            modeliPhoneXHeight="640px"
            modeliPhoneXPosition="relative"
          />
        </div>
      </div>
    </div>
  );
};

export default RewardManagementContainer;
