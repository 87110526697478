import { FunctionComponent } from "react";
import LoyaltyProgramContainer from "./loyalty-program-container";

const LogoClouds06: FunctionComponent = () => {
  return (
    <div className="resp-loality-program-section bg-mediumslateblue-200 w-[1440px] overflow-hidden flex flex-col items-start justify-start py-20 px-28 box-border">
      <img className="relative w-[1216px] h-px" alt="" src="/line.svg" />
      <LoyaltyProgramContainer
        sectionTitle="200+ Loyalty Programs"
        description="Track loyalty programs across hotels, airlines, cards all around the globe"
        clientLogoMark="/-client-logo-mark7.svg"
        clientLogoMark1="/-client-logo-mark8.svg"
        clientLogoMark2="/-client-logo-mark9.svg"
        clientLogoMark3="/-client-logo-mark10.svg"
        clientLogoMark4="/-client-logo-mark11.svg"
        clientLogoMark5="/-client-logo-mark12.svg"
        clientLogoMark6="/-client-logo-mark13.svg"
      />
    </div>
  );
};

export default LogoClouds06;
