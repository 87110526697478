import { FunctionComponent, useMemo, type CSSProperties } from "react";

type Property1ShopifyType = {
  property1ShopifyProperty1?: string;

  /** Style props */
  property1ShopifyIconWidth?: CSSProperties["width"];
  property1ShopifyIconHeight?: CSSProperties["height"];
  property1ShopifyIconPosition?: CSSProperties["position"];
};

const Property1Shopify: FunctionComponent<Property1ShopifyType> = ({
  property1ShopifyProperty1,
  property1ShopifyIconWidth,
  property1ShopifyIconHeight,
  property1ShopifyIconPosition,
}) => {
  const property1ShopifyIconStyle: CSSProperties = useMemo(() => {
    return {
      width: property1ShopifyIconWidth,
      height: property1ShopifyIconHeight,
      position: property1ShopifyIconPosition,
    };
  }, [
    property1ShopifyIconWidth,
    property1ShopifyIconHeight,
    property1ShopifyIconPosition,
  ]);

  return (
    <img
      className="w-[84px] h-[94px]"
      alt=""
      src={property1ShopifyProperty1}
      style={property1ShopifyIconStyle}
    />
  );
};

export default Property1Shopify;
