import { FunctionComponent } from "react";
import TypeTitleDescriptionColu from "./type-title-description-colu";
import FeaturesContainer from "./features-container";

const Features12: FunctionComponent = () => {
  return (
    <div className="resp-reward-section bg-gray flex flex-col items-center justify-start py-24 px-28 gap-[64px]">
      <TypeTitleDescriptionColu
        sectionTitle="Maximize your rewards"
        description="Get access to multiple resources through our app"
        typeTitleDescriptionColuBorder="unset"
        typeTitleDescriptionColuBoxSizing="unset"
        typeTitleDescriptionColuFlexShrink="unset"
        typeTitleDescriptionColuPadding="unset"
        typeTitleDescriptionColuWidth="800px"
        sectionTitleTextAlign="center"
        descriptionTextAlign="center"
      />
      <FeaturesContainer
        headline="Personalized"
        description="We will suggest your the best possible way to use your rewards according to your needs. "
        headline1="Power to you"
        description1="We will help you understand how cards, rewards can help your pocket"
        description2="Simple user interface to focus on what matters the most"
        headline2="Know it all"
        description3={`Get information about your loyalty program & its usage.`}
        headline3="Leave it to us"
        description4="We will remember things for you so you don’t have to. "
        baseFeatureIcon="/base-feature-icon10.svg"
        headline4="Live Dreams"
        description5={`Achieve your goals of flying business class, owning an iPhone & more.`}
      />
    </div>
  );
};

export default Features12;
