import { FunctionComponent } from "react";
import BreakpointDesktop from "./breakpoint-desktop";

const BottomFooter: FunctionComponent = () => {
  return (
    <div className="flex flex-col items-center justify-start resp-w-100">
      <BreakpointDesktop
        iconImageUrl="/logomark1.svg"
        pageTitle="Privacy Policy"
        // linkText="Help"
        copyrightText="© 2024 Voyage Inn Vantage Private Limited. All rights reserved"
        emailAddress="Email: contact@magnify.club"
        companyAddress="Address: A-17 2nd floor,Gururaj Soc, Panchpakadi, Naupada, Thane- 400602, Maharashtra"
        showLogomarkIcon={false}
        showLinksRow1={false}
        showAbout={false}
        showFeatures={false}
      />
    </div>
  );
};

export default BottomFooter;
