import Manifest from "./Manifest";

const pageTitles: { [key: string]: string } = {
  "/": "Magnify: Your Rewards Companion",
  "/privacy-policy": "Privacy Policy",
  "/terms-and-conditions": "Terms and Conditions",
  "/cancellation-refund-policy": "Cancellation & Refund Policy",
  "/club-vistara-sweet-spots-before-merger":
    "Club Vistara & Air India Sweet Spots",
  "/club-vistara-sweet-spots-before-merger/results":
    "Club Vistara & Air India Sweet Spots Results",
};

const metaDescriptions: { [key: string]: string } = {
  "/": "Manage your rewards across Hotels, Airlines, Credit Cards. Get regular insights & notifications about ongoing offers.",
  "/privacy-policy": "Read our privacy policy.",
  "/terms-and-conditions": "View our terms and conditions.",
  "/cancellation-refund-policy": "Review our cancellation and refund policy.",
  "/club-vistara-sweet-spots-before-merger":
    "Explore sweet spots for Club Vistara & Air India.",
  "/club-vistara-sweet-spots-before-merger/results":
    "Check out your sweet spot results.",
};

const ImageURLs: { [key: string]: string } = {
  "/": `${Manifest.SITE_URL}/fallbackImg.jpg`,
  "/privacy-policy": `${Manifest.SITE_URL}/fallbackImg.jpg`,
  "/terms-and-conditions": `${Manifest.SITE_URL}/fallbackImg.jpg`,
  "/cancellation-refund-policy": `${Manifest.SITE_URL}/fallbackImg.jpg`,
  "/club-vistara-sweet-spots-before-merger": `${Manifest.SITE_URL}/sweet-spots-png`,
  "/club-vistara-sweet-spots-before-merger/results": `${Manifest.SITE_URL}/sweet-spots.png`,
};

export function getTitleAndMetaDescription({ pathname }: { pathname: string }) {
  const title = pageTitles[pathname] || "Page Not Found";
  const metaDescription =
    metaDescriptions[pathname] ||
    "The page you are looking for does not exist.";
  return { title, metaDescription };
}

export function setTitleAndMetaDescription({
  title,
  metaDescription,
}: {
  title: string;
  metaDescription: string;
}) {
  document.title = title;
  const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
    'head > meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.content = metaDescription;
  } else {
    const newMetaDescriptionTag = document.createElement("meta");
    newMetaDescriptionTag.name = "description";
    newMetaDescriptionTag.content = metaDescription;
    document.head.appendChild(newMetaDescriptionTag);
  }
}

export function setOpenGraphAndTwitterMetaTags({
  title,
  metaDescription,
}: {
  title: string;
  metaDescription: string;
}) {
  const metaTags = [
    { property: "og:title", content: title },
    { property: "og:description", content: metaDescription },
    { property: "og:type", content: "website" },
    { property: "og:url", content: window.location.href },
    {
      property: "og:image",
      content: ImageURLs[window.location.href],
    },

    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: metaDescription },
    {
      name: "twitter:image",
      content: ImageURLs[window.location.href],
    },
  ];

  const shouldAddInfoToLink =
    window.location.href ===
      `${Manifest.SITE_URL}/club-vistara-sweet-spots-before-merger` ||
    window.location.href ===
      `${Manifest.SITE_URL}/club-vistara-sweet-spots-before-merger/results`;

  if (shouldAddInfoToLink) {
    metaTags.forEach(({ property, name, content }) => {
      let element = document.querySelector(
        `head > meta[${property ? `property="${property}"` : `name="${name}"`}]`
      );
      if (!element) {
        element = document.createElement("meta");

        if (property) {
          element.setAttribute("property", property);
        } else if (name) {
          element.setAttribute("name", name);
        }
        document.head.appendChild(element);
      }

      element.setAttribute("content", content);
    });
  }
}

export function setCanonicalLink({
  title,
  metaDescription,
}: {
  title: string;
  metaDescription: string;
}) {
  let link = document.querySelector('link[rel="canonical"]');
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }
  link.setAttribute("href", window.location.href);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: window.location.href,
    name: title,
    description: metaDescription,
  };

  const scriptTag = document.createElement("script");
  scriptTag.type = "application/ld+json";
  scriptTag.text = JSON.stringify(structuredData);
  document.head.appendChild(scriptTag);

  return () => {
    document.head.removeChild(scriptTag);
  };
}
